import $ from "jquery"

export function logViewHistory(succeeded = null, failed = null) {
  $.ajax({
    url: window.API_URL.web.log,
    method: "POST",
    dataType: "json",
    data: {
      user: window.APP_DATA.user,
      chapter: window.APP_DATA.chapter
    }
  }).done(function (data) {
    if (succeeded instanceof Function) {
      succeeded();
    }
  }).fail(function (data) {
    if (failed instanceof Function) {
      failed(data);
    }
  });
}
import $ from "jquery"
import {logViewHistory} from "./log-view-history";

$(function () {
  const video = $('video#mainV');
  const navigateModal = $('section.modal_test');
  const navigateButton = $('a#nav_test');
  const timePointLink = $('a.js-video_control');
  const test = $('#chapter_test');

  video.mouseover(function () {
    this.setAttribute("controls", "controls");
  });

  video.mouseout(function () {
    this.removeAttribute('controls');
  });

  video.on('ended', function () {
    logViewHistory(function () {
      typeof test !=='undefined' && test.css('display') === 'none' && navigateModal.remodal().open();
    }, function (data) {
      alert(data.responseJSON.message);
    });
  });

  navigateButton.on('click', function () {
    navigateModal.remodal().close();
    test.css('display', 'block');
  });

  timePointLink.on('click', function () {
    const [min, sec] = $(this).text().split(':').map((v) => Number(v));

    video.get(0).currentTime = min * 60 + sec;
    video.get(0).play();
  });
});

import $ from 'jquery'
import '../base'
import "remodal/dist/remodal.min"
import "./snipets/video-controll"
import {togglePasswordVisibility} from "./snipets/togglePasswordVisiblity";

$(function () {
  $(document).on('click', '.area_check_video > .ttl', function () {
    $(this).hide(0);
    $(this).next().show(0);
  });

  $(document).on('click', '.list_check_video label[id*="confirm_"]', function () {
    let num = $(this).parents('li').index() + 1;
    $('.area_confirm_video').addClass('view');
    $('.area_confirm_video a[data-remodal-target]').attr('data-remodal-target', 'learning_' + num);
  });


  $(document).on('click', '.area_confirm_video .button_text, .area_confirm_video .btn_', function () {
    $('.area_confirm_video').removeClass('view');
  });

  $(function () {
    let Win = $(window).width();
    if (Win <= 1020) {
      let Hei = $('.area_contents_aside').innerHeight();
      $('.body_contents_aside').css('top', Hei + 'px');
    }
  });

  $(document).on('click', '.area_contents_aside', function () {
    let Hig = $(window).height() - $('header').innerHeight() + $('.area_contents_aside').innerHeight();
    $('.body_contents_aside').toggleClass('view');
    if ($('.body_contents_aside').hasClass('view')) {
      $('.body_contents_aside').css('height', Hig + 'px');
    } else {
      $('.body_contents_aside').css('height', '0px');
    }
  });

  $(document).on('click', '.list_word_video li', function () {
    let Wd = $(this).find('.cnt p').text();
    if ($(this).hasClass('current')) {
      $('.list_word_video li').removeClass('current');
      $('.area_word_video .description').text('選択したキーワードを説明します。');
      $('.area_word_video .description').addClass('placeholder');
    } else {
      $('.list_word_video li').removeClass('current');
      $(this).addClass('current');
      $('.area_word_video .description').text(Wd);
      $('.area_word_video .description').removeClass('placeholder');
    }

  });

  let pushedTimes = 0;

  $('button#toggle_visibility').on('click', function () {
    ++pushedTimes;

    togglePasswordVisibility(pushedTimes);
  })
});

